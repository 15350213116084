.balloonNone {
  padding: 5px 5px;
  width: 90%;
  min-height: 2em;
  color: #555;
  font-size: 16px;
  background: #f0f0f0;
  text-align: center;
  border-radius: 10px;
}



.balloonNone p {
  margin: 0;
  padding: 0;
}

.balloonLeft {
  position: relative;
  display: inline-block;
  margin: 0 0 0 15px;
  padding: 8px 10px;
  min-width: 60px;
  max-width: 70%;
  min-height: 2.5em;
  color: #555;
  font-size: 16px;
  background: #e0edff;
  border-radius: 10px;
}

.balloonLeft:before {
  content: "";
  position: absolute;
  top: 25px;
  left: -20px;
  margin-top: -15px;
  border: 10px solid transparent;
  border-right: 15px solid #e0edff;
}

.balloonLeft p {
  margin: 0;
  padding: 0;
}

.balloonRight {
  position: relative;
  display: inline-block;
  margin: 0 15px 0 0;
  padding: 8px 10px;
  min-width: 60px;
  max-width: 70%;
  min-height: 2.5em;
  color: #555;
  font-size: 16px;
  background: #e0edff;
  border-radius: 10px;
}

.balloonRight:before {
  content: "";
  position: absolute;
  top: 25px;
  left: calc(100% - 5px);
  margin-top: -15px;
  border: 10px solid transparent;
  border-left: 15px solid #e0edff;
}

.balloonRight p {
  margin: 0;
  padding: 0;
}
